import React, { useEffect } from 'react'
import PageHeader from './Sections/PageHeader'
import DonateMain from './Sections/DonateMain'
import useContent from '../../../hooks/useContent'
import useSectionContent from '../../../hooks/useSectionContent'

const Donate = () => {

    const { getPageContent, pageContent } = useContent()

    useEffect(() => {
        getPageContent("donate")
    }, [getPageContent])

    const RenderContent = (section) => {
        const { content } = useSectionContent(pageContent, section)
        return content
    }

    return (
        <>
            <PageHeader sectionContent={RenderContent("hero")} />
            <DonateMain sectionContent={RenderContent("donation")} />
        </>
    )
}

export default Donate