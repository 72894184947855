import React, { useEffect } from 'react'
import { DarkGreySeparator, LightGreySeparator } from '../../../styled-components/Base/Base'
import WhoWeAre from './Sections/WhoWeAre'
import WhoWeServe from './Sections/WhoWeServe'
import useContent from '../../../hooks/useContent'
import useSectionContent from '../../../hooks/useSectionContent'
import PageHeader from './Sections/PageHeader'

const OurStory = () => {

  const { getPageContent, pageContent } = useContent()

  useEffect(() => {
    getPageContent("about")
  }, [getPageContent])

  const RenderContent = (section) => {
    const { content } = useSectionContent(pageContent, section)
    return content
  }

  return (
    <>
      <PageHeader sectionContent={RenderContent("hero")} />
      <LightGreySeparator />
      <WhoWeAre sectionContent={RenderContent("whoweare")} />
      <DarkGreySeparator />
      <WhoWeServe sectionContent={RenderContent("whoweserve")} />
    </>

  )
}

export default OurStory