
const usePreviousRef = () => {
   
    const getRef = (val) => {
        return val
    }

    return {getRef}
}

export default usePreviousRef